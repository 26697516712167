import HeaderBase, {
  NavLink,
  NavLinks,
  PrimaryLink,
} from "components/headers/light.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { SectionHeading } from "components/misc/Headings.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg";
import styled from "styled-components";
import tw from "twin.macro";
import i18n, { languages } from "../../helpers/i18n";
import aboutJpg from "../../images/owner.webp";
import { Popover } from "@headlessui/react";
import enLang from "../../images/lang/circle_lang_en.png";
import viLang from "../../images/lang/circle_lang_vi.png";
import ptLang from "../../images/lang/circle_lang_pt.png";
import thLang from "../../images/lang/circle_lang_th.png";
import idLang from "../../images/lang/circle_lang_id.png";
import esLang from "../../images/lang/circle_lang_es.png";

const circle = {
  en: enLang,
  vi: viLang,
  pt: ptLang,
  th: thLang,
  id: idLang,
  es: esLang,
};
const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const QuotesLeftIcon = tw(
  QuotesLeftIconBase
)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`;
const Quote = tw.blockquote``;
const CustomerName = tw.p`mt-4 font-bold`;
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`;
const LangItem = tw.div`cursor-pointer flex items-center gap-2 hover:bg-gray-200 p-2 rounded-md`;

export default ({
  heading = i18n.t("Tự động hóa quy trình gửi tin."),
  description = i18n.t(
    "Tối ưu hoá tệp khách hàng nhằm nâng cao hiệu suất chốt sale của doanh nghiệp, giảm chi phí quảng cáo tối đa; Phần mềm buộc phải có nếu bạn là nhà bán hàng trên Facebook."
  ),
  imageSrc = aboutJpg,
  imageDecoratorBlob = true,
  primaryButtonUrl = `https://chrome.google.com/webstore/detail/remarketing/enlgkmbkbkglicgibhffepnaniamjaej?hl=${i18n.language}`,
  primaryButtonText = i18n.t("Dùng Thử Miễn Phí"),
  buttonRounded = true,
  features = [
    i18n.t("Có mặt tại 7 Quốc gia."),
    i18n.t("Tuân thủ chính sách Facebook."),
    // i18n.t("Sử dụng được trên Zalo / Pancake."),
    i18n.t("Tin tưởng bởi Google Extension Store."),
  ],
  testimonial = {
    quote: i18n.t(
      "Chúng tôi tạo ra phần mềm này trong nỗ lực níu kéo lại những khách hàng tiềm năng bị bỏ lỡ, và hiệu quả của nó là không tưởng. Nên chúng tôi đã quyết định chia sẻ nó."
    ),
    customerName: "",
    customerCompany: "Owner | Vivoo Global Inc.",
  },
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const scrollTo = (id) => {
    try {
      const el = document.getElementById(id);
      window.scrollTo({
        top: el
          ? el.offsetParent.offsetTop
            ? el.offsetParent.offsetTop
            : el.offsetTop
          : 0,
        behavior: "smooth",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const changeLang = async (lang, locale) => {
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem("language", lang);
      localStorage.setItem("locale", locale);
      window.location.reload();
    } catch (e) {
      console.log(lang, e);
    }
  };

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/#!" onClick={() => scrollTo(null)}>
        {i18n.t("Giới thiệu")}
      </NavLink>
      <NavLink href="/#!" onClick={() => scrollTo("features")}>
        {i18n.t("Nội tại")}
      </NavLink>
      <NavLink href="/#!" onClick={() => scrollTo("pricing")}>
        {i18n.t("Chi phí sử dụng")}
      </NavLink>
      {/* <NavLink href="/#">Contact Us</NavLink> */}
      <NavLink href="/#!" onClick={() => scrollTo("faq")}>
        {i18n.t("Câu hỏi thường gặp")}
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <Popover
        style={{
          display: "inline-block",
          position: "relative",
          outline: "none!important",
          zIndex: 9999,
        }}
      >
        {({ open }) => {
          document.body.style.overflowY = !open ? "auto" : "hidden";
          return (
            <>
              <Popover.Button style={{ outline: "none", padding: 0 }}>
                <NavLink
                  href="/#!"
                  tw="lg:ml-12!"
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    outline: "none",
                  }}
                >
                  <img
                    src={circle[i18n.language]}
                    width={20}
                    height={20}
                    style={{
                      width: "20px!important",
                      height: "20px!important",
                    }}
                    alt=""
                  />
                  Language
                </NavLink>
              </Popover.Button>
              <Popover.Panel
                style={{
                  background: "#fff",
                  position: "absolute",
                  border: "1px solid #ddd",
                  borderRadius: 8,
                  right: 0,
                  top: 40,
                  padding: 10,
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 1,
                }}
              >
                {languages.map((e) => (
                  <LangItem
                    onClick={() => changeLang(e.code, e.locale)}
                    key={e.code}
                  >
                    <img width={25} src={circle[e.code]} alt="" />
                    {e.name}
                  </LangItem>
                ))}
              </Popover.Panel>
            </>
          );
        }}
      </Popover>
      <PrimaryLink
        css={buttonRoundedCss}
        href={primaryButtonUrl}
        target="_blank"
        style={{
          verticalAlign: 4,
        }}
      >
        {i18n.t("Tải xuống")}
      </PrimaryLink>
    </NavLinks>,
  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <PrimaryButton
                target="_blank"
                as="a"
                href={primaryButtonUrl}
                css={buttonRoundedCss}
              >
                {primaryButtonText}
              </PrimaryButton>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
                <Testimonial>
                  <QuotesLeftIcon />
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerCompany>
                    {testimonial.customerCompany}
                  </CustomerCompany>
                </Testimonial>
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
